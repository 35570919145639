<script setup>
defineProps({
    closeable: {
        type: Boolean,
        required: false,
    },
});
const open = ref(true);
</script>
<template>
    <div
        v-if="open"
        class="p-4 sm:p-6 bg-brand-lighter text-yellow-700 border-l-4 border-brand relative"
    >
        <slot />
        <CommonButton
            v-if="closeable"
            class="absolute right-0 top-0 p-3"
            @click="open = false"
        >
            <IconClose class="w-3 h-3 text-yellow-700" />
        </CommonButton>

    </div>
</template>
